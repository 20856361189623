import React, { useState , useEffect } from 'react';
import { Link } from 'react-router-dom';
import swal from 'sweetalert';

const Contact = (props)=>  {


    useEffect(() => {
        window.scrollTo(0, 0)

    }, []);




        return (
            <div>
                <div className="bg-light py-3">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 mb-0"><Link to="/">Home</Link> <span className="mx-2 mb-0">/</span> <strong className="text-black">Traffic Violations Policy</strong></div>
                        </div>
                    </div>
                </div>
                <div className="container" style={{width : "99% " ,padding : "80px 30px"}}>
                        <div className="row">
                            <div className="col-xl-12 col-lg-12 col-md-12">
                                <div style={{fontWeight :"bold"}}>
                                <h1>Traffic Violations Policy</h1>

<p>All drivers using Falcon Rides' vehicles must comply with the rules and regulations of the road, covering speed restrictions, parking, and lane discipline. Falcon Rides emphasizes the importance of strict adherence to these regulations in the UAE. Non-compliance may result in substantial fines. The most common traffic violations recorded, based on data available to Falcon Rides, include:</p>

<ul>
    <li>Exceeding the speed limit</li>
    <li>Failure to pay for parking</li>
    <li>Poor lane discipline</li>
    <li>Failure to follow traffic signals</li>
    <li>Failure to wear seat belts</li>
    <li>Using a phone while driving</li>
</ul>

<p>Falcon Rides is committed to assisting with the logistics of any fines incurred during the rental period. However, please be aware that the associated cost will be passed on to the customer. Additionally, a 12% Admin Fee is applicable to all traffic fines for this service. Invoices for these charges will be issued as and when they are posted by the relevant authorities, which may result in a delay between the violation date and the customer being notified about it.</p>

<p>Falcon Rides is not responsible for any delays in invoicing or the accuracy of fines, which may be a result of the information received from various government systems.</p>


                                </div>
                            </div>
                        </div>
                </div>
            </div>
        )
    
}


export default Contact;